import FormSection from '../../../../Core/Forms/FormSection';
import { Row, Col, Stack } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import JobDescription from '../../../Common/Components/JobDescription';
import CurrencyField from '../../../../Core/Forms/CurrencyField';
import InputField from '../../../../Core/Forms/InputField';
import PlusButton from '../../../../Core/Forms/PlusButton';
import MinusButton from '../../../../Core/Forms/MinusButton';

const JobDescriptions = ({ isSalesQuote, disabled, isJobSheet}) => {    
    const { control, getValues, setValue } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: "JobDescriptions",
    });

    const updateTotal = () => {
        
        let total = 0;

        for (let i = 0; i < fields.length; i++) {
            
            let lineTotal = parseFloat(getValues(`JobDescriptions.${i}.LineTotal`));
            
            total += lineTotal ? lineTotal : 0;
        }

        setValue("JobDescriptionsTotal", total.toFixed(2));
    }

    const getNextTaskOrderNumber = () => {
        let taskOrderNumbers = getValues("JobDescriptions").map(jobDescription => jobDescription.TaskOrder);
        let taskOrdeNumber = Math.max(...taskOrderNumbers);
        
        return ++taskOrdeNumber;
    }

    return(
        <FormSection title="Job Description">
            <Stack gap={3}>
                <Row>
                    <Stack gap={3}>
                        {fields.map((jobDescription, index) =>
                            <JobDescription
                                key={`JobDescription-${index}`}
                                index={index}
                                updateJobTotal={(index, lineTotal) => {setValue(`JobDescriptions.${index}.LineTotal`, lineTotal); updateTotal();}}
                                isSalesQuote={isSalesQuote}
                                jobDescription={jobDescription}
                                updateTotal={updateTotal}
                                disabled={disabled}
                            />
                        )}
                    </Stack>
                </Row>
                {
                    !disabled && isSalesQuote &&
                        <Row>
                            <Col className="col-6 d-flex justify-content-start">
                                <Stack direction="horizontal">
                                    <PlusButton
                                        onClick={
                                            () => {
                                                append({
                                                    Id: null,
                                                    Task: "",
                                                    Squares: null,
                                                    LinealMeters: null,
                                                    UnitCost: "0",
                                                    LineTotal: "0",
                                                    AdditionalInformation: "",
                                                    TaskOrder: getNextTaskOrderNumber()
                                                })
                                            }
                                        }
                                        disabled={disabled}                                        
                                    />
                                    {
                                        fields.length > 1 &&
                                        <MinusButton onClick={() => {remove(-1); updateTotal()}} disabled={disabled}/>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                }
                {isSalesQuote &&
                    <Row>
                        <Col className="col-6 offset-6">
                            <CurrencyField label="Total" name="JobDescriptionsTotal" textAlignRight decimalPlaces={2} value={getValues("JobDescriptionsTotal")} disabled/>
                        </Col>
                    </Row>
                }
                {!isJobSheet &&
                    <InputField label="Additional Information" name={`AdditionalInformation`} type="textarea" rows={7} {...(disabled && ({ disabled }))}/>
                }
            </Stack>
        </FormSection>
    )
}

export default JobDescriptions;