import * as yup from "yup";
import { getHazard, getHazards } from "../../../../../Utilities/Enums";

const PrestartFormValidationSchema = yup.object().shape({
    Date: yup
            .date("Must be a valid date")
            .nullable(true)
            .transform(value => (value instanceof Date && !isNaN(value) ? value : null))
            .typeError("Must be a valid date"),
    Hazards: yup.array()                        
                    .of(
                        yup.object().shape({
                            HazardControls: yup.string().nullable()
                        }).when((values, schema) => {
                            if (values[0]?.HazardName !== null) {
                                return schema.shape({                                    
                                    HazardControls: yup.string().required("Control value required")
                                });
                            } else if (values[0]?.HazardName === null) {
                                return schema.shape({
                                    HazardControls: yup.string().nullable().notRequired()
                                });
                            } else {
                                let keys = Object.keys(getHazards());

                                let hazards = keys.map(key => getHazard(getHazards()[key]));

                                if (!hazards.includes(values[0]?.HazardName)) {

                                    if (!values[0]?.HazardControls) {

                                        return schema.shape({
                                            HazardControls: yup.string().required("Control value required")
                                        });
                                    } else {

                                        return schema.shape({
                                            HazardControls: yup.string().nullable().notRequired()
                                        });
                                    }
                                }

                                return schema.shape({
                                    HazardControls: yup.string().nullable().notRequired()
                                });
                            }
                        })
                    ),
    FormComplete: yup.boolean().oneOf([true], "This box must be checked to submit the form")
});

export default PrestartFormValidationSchema;