import { Col, Row, Table, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../../../Stores/SessionStore";
import { Roles } from "../../../../../Utilities/Enums";
import PageContainer from "../../../../Core/PageContainer";
import Headerbar from "./Headerbar";
import Panel from "../../../../Core/Panel";
import ISODate from "../../../../Core/ISODate";
import Actions from "../../../Common/Components/Actions";
import ConfirmModal from "../../../../Core/Forms/ConfirmModal";
import MessageModal from "../../../../Core/Forms/MessageModal";
import { getStatus } from "../../../../../Utilities/Enums";

export default function SalesQuotes() {
	const store = useSessionStore();
	const navigate = useNavigate();
	
	const [quotes, setQuotes] = useState(null);
	const [deleteModal, setDeleteModal] = useState({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}});
    const [modal, setModal] = useState({show: false, header: "", message: "", onClose: () => {}});

	async function getSalesQuotes(search, status) {
		setQuotes(null);

		let result = await store.SalesQuotesService.GetSalesQuotes(search, status);
		
		if(result && result.Data) {

			result.Data.sort((a, b) => b.SQNumber - a.SQNumber);
			
			setQuotes(result.Data);
		}
	}

	async function getSalesQuotesByTeamLeader(teamLeaderId) {
		if (!teamLeaderId) {
			getSalesQuotes("", "");
			return;
		}

		setQuotes(null);

		let result = await store.SalesQuotesService.GetSalesQuotesByTeamLeader(teamLeaderId);
		
		if(result && result.Data) {

			result.Data.sort((a, b) => b.SQNumber - a.SQNumber);
			
			setQuotes(result.Data);
		}
	}

	function getStatuses(quote) {
		let status = quote.SubmittedToAdminOn ? "Authorisation Pending" : "Estimate in Progress";
		let statuses = [quote.EstimateAccepted, quote.DepositReceived, quote.Invoiced, quote.JobCompleted]
		let statusesLabel = [];

		for (let i = 0; i < statuses.length; i++) {
			if (statuses[i]) {
				statusesLabel.push(getStatus(i));
			}
		}

		if (statusesLabel.length > 0) {
			status = statusesLabel.join(", ");
		}

		return status;
	}

	useEffect(() => {
		getSalesQuotes("", "");
	}, []);

	const currency = (value) => {
		return "$ " + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	function confirmDelete(id) {
        setDeleteModal({
            show: true,
            header: "Confirm Delete",
            message: "Are you sure you want to delete this estimate? Please note all associated Job Sheets and other forms will also be deleted.",
            confirmAction: () => {deleteEstimate(id)},
            onClose: () => {setDeleteModal({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}})}
        });
    }

    async function deleteEstimate(id) {
		console.log(id)
        setDeleteModal({show: false, header: "", message: "", confirmAction: () => {}, onClose: () => {}});
        let result = await store.SalesQuotesService.DeleteSalesQuote(id);

        if (result && result.Success) {
            setModal({
                show: true,
                header: "Success",
                message: "The estimate has been deleted successfully.",
                onClose: () => {setModal({show: false, header: "", message: "", onClose: () => {}})}
            });
            
            getSalesQuotes("", "");
        } else {
            setModal({
                show: true,
                header: "Error",
                message: "An error occurred while deleting the estimate. Please try again.",
                onClose: () => {setModal({show: false, header: "", message: "", onClose: () => {}})}
            });
        }
    }

	return 	<PageContainer title="Projects" subtitle="Estimates">
				<Panel>
					<Headerbar onFilterClicked={ (search, status) => getSalesQuotes(search, status) } onTeamLeaderSelected={ (teamLeaderId) => getSalesQuotesByTeamLeader(teamLeaderId) }></Headerbar>
					<hr/>
					<Row>
						<Col>							
							<Table>
								<thead>
									<tr>
										<th>EST Number</th>
										<th className="d-none d-lg-table-cell">Date</th>
										<th className="d-none d-lg-table-cell">Sales Rep</th>
										<th className="d-none d-xxl-table-cell">Date Scheduled</th>
										<th className="d-none d-md-table-cell">Client Name</th>
										<th className="d-none d-lg-table-cell">Job Number</th>
										<th className="d-none d-lg-table-cell">Team Leader</th>
										<th className="d-none d-xxl-table-cell">Status</th>
										{
											store.Session.Role === Roles.Admin &&
											<th className="text-end">Total</th>
										}
										<th className="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{
										quotes && quotes.map((quote, index) => 
											<tr key={`Quote-${index}`}>
												<td>{ `EST${quote.SQNumber}` }</td>
												<td className="d-none d-lg-table-cell"><ISODate date={quote.Date}/></td>
												<td className="d-none d-lg-table-cell">{ quote.SalesRep }</td>
												<td className="d-none d-xxl-table-cell">{quote.DateScheduled.substring(0, 4) === "0001" ? "" : <ISODate date={quote.DateScheduled}/>}</td>
												<td className="d-none d-md-table-cell">{ quote.ClientName }</td>
												<td className="d-none d-lg-table-cell">{ `JS-EST${quote.SQNumber}` }</td>
												<td className="d-none d-lg-table-cell">{ quote.TeamLeader }</td>
												<td className="d-none d-xxl-table-cell">{ getStatuses(quote) }</td>
												{
													store.Session.Role === Roles.Admin &&
													<td className="text-nowrap" align="right">{ currency(quote.Total) }</td>
												}
												<td align="center">
													<Actions
														edit={{ action: () => navigate(`/SalesQuotes/${quote.Id}`, {state: { action: "edit" }}), title: "Edit Estimate" }}
														view={{ action: () => navigate(`/SalesQuotes/${quote.Id}`, {state: { action: "view" }}), title: "View Estimate" }}
                                                        del={store.Session.Role === Roles.Admin ? { action: () => confirmDelete(quote.Id), title: "Delete Estimate" } : null}
													/>
												</td>
											</tr>
										)
									}
								</tbody>
							</Table>
							{
								!quotes &&
								<div className="text-center">
									<Spinner></Spinner>
								</div>
							}
						</Col>
					</Row>
				</Panel>
                <ConfirmModal modal={deleteModal}/>
                <MessageModal modal={modal}/>
			</PageContainer>
}