import { useState, useEffect } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { Form } from "react-bootstrap";
import { useFormContext } from 'react-hook-form';
import InputField from '../../../Core/Forms/InputField';
import FormSection from '../../../Core/Forms/FormSection';
import { getJobRequirement, JobRequirementLabels } from "../../../../Utilities/Enums";

const JobRequirements = ({ requirements, disabled }) => {
    const { setValue } = useFormContext();
    
    const [jobRequirements, setJobRequirements] = useState([]);

    useEffect(() => {
        if (jobRequirements.length > 0) return;

        let initialRequirements = [0];

        for (let i = 1; i < 19; i++) {
            initialRequirements.push(requirements.includes(i));
        }

        setJobRequirements(initialRequirements);
    }, [requirements])
    
    const CheckBox = ({label, id}) => {
        return (
            <Form.Check >
                <Form.Check.Input
                    type={"checkbox"}
                    disabled={disabled}
                    checked={ jobRequirements[id] }
                    onChange={ e => setRequirements(id, e.target.checked) }
                />
                <Form.Check.Label>{label}</Form.Check.Label>
            </Form.Check>
        )
    }

    const setRequirements = (index, value) => {
        let currentRequirements = [...jobRequirements];
        currentRequirements[index] = value;
        let newRequirements = [];

        for (let i = 1; i < currentRequirements.length + 1; i++) {
            if (currentRequirements[i]) {
                newRequirements.push(i);
            }
        }

        setJobRequirements(currentRequirements);
        setValue("JobRequirements", newRequirements);
    }

    return (
        <FormSection title="Job Requirements">
            <Stack gap={3}>
                <Row>
                    <Col className="col-6 col-md-3 mb-3 mb-md-0">
                        <Stack gap={3}>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.Power)} id={JobRequirementLabels.Power}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.SlurryControl)} id={JobRequirementLabels.SlurryControl}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.Parking)} id={JobRequirementLabels.Parking}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.DrawingsApproved)} id={JobRequirementLabels.DrawingsApproved}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.QARequired)} id={JobRequirementLabels.QARequired}/>
                        </Stack>
                    </Col>
                    <Col className="col-6 col-md-3 mb-3 mb-md-0">
                        <Stack gap={3}>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.JointRepairs)} id={JobRequirementLabels.JointRepairs}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.HeightsLevels)} id={JobRequirementLabels.HeightsLevels}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.CrackRepairs)} id={JobRequirementLabels.CrackRepairs}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.Inside)} id={JobRequirementLabels.Inside}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.PS3Required)} id={JobRequirementLabels.PS3Required}/>
                        </Stack>
                    </Col>
                    <Col className="col-6 col-md-3">
                        <Stack gap={3}>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.TASSPRequired)} id={JobRequirementLabels.TASSPRequired}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.WaterOnSite)} id={JobRequirementLabels.WaterOnSite}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.Generator)} id={JobRequirementLabels.Generator}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.Outside)} id={JobRequirementLabels.Outside}/>
                        </Stack>
                    </Col>
                    <Col className="col-6 col-md-3">
                        <Stack gap={3}>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.ScaffoldingRequired)} id={JobRequirementLabels.ScaffoldingRequired}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.ProtectionRequired)} id={JobRequirementLabels.ProtectionRequired}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.RubbishDisposal)} id={JobRequirementLabels.RubbishDisposal}/>
                            <CheckBox label={getJobRequirement(JobRequirementLabels.InsideOutside)} id={JobRequirementLabels.InsideOutside}/>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Stack gap={3}>
                        <InputField label="Special Machinery Required (ie: gear not carried daily - chase cutter etc)" name="SpecialMachineryRequired" type="textarea" disabled={disabled}/>
                        <InputField label="Customer Brief & Site Notes" name="CustomerBrief" type="textarea" disabled={disabled}/>
                    </Stack>
                </Row>
            </Stack>
        </FormSection>
    )
}

export default JobRequirements;